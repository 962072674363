import styled from "@emotion/styled";
import { CheckBoxContainer, FirstCellDiv, RowDivStyleDesktop } from "./elements";
import { formatDate, formatPingTime, formatTime } from "../../../../helper/unitsFormatting";
import { getRouterSpeed, nullOrValue } from "../../../../helper/utils";
import * as R from "ramda";
import ProgressBar from "../common/ProgressBar";
import { getSpeedKind } from "../common/resultUtils";
import { NQRecord } from "../../../../features/history/types";
import { historyRecordSpeedUnits, historyRecordSpeedValues } from "./historyTableData";
import { useAppDispatch } from "../../../../hooks";
import { CommonButtonDiv } from "../../elements/Buttons";
import { printReport } from "../../../../features/print/printSlice";
import BottomInfo from "./BottomInfo";
import t from "../../../../helper/t";
import getSpeedtestId from "../../../../helper/utils/getSpeedtestId";
import { getDeviceText } from "../../../../helper/getDeviceText";
import { ModemFinalKind, ModemNotRunReason } from "@visonum/network-quality-sdk";
import NavigationButton from "../../elements/NavigationButton";

import exportSvg from "../../../../../assets/icons/splus3/export.svg";

const RowContainer = styled.div(({ theme }) => ([
    theme.splus3.background.white,
    {
        display: "flex",
        flexDirection: "column",
    },
]));

const RowDiv = styled.div([
    RowDivStyleDesktop,
]);

const CellDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
    }
]));

const SimpleCellDiv = styled(CellDiv)(({ theme }) => ([
    theme.splus3.typography.default,
    {
        gap: 10,
        lineHeight: "100%",
        span: [
            theme.splus3.typography.small,
        ]
    }
]));

const ExtendedCellDiv = styled(CellDiv)(({ theme }) => ([
    {
        gap: 8,
    }
]));

const InlineCellDiv = styled(CellDiv)(({ theme }) => ([
    {
        flexDirection: "row",
        gap: 8,
        justifyContent: "space-between",
    }
]));


const SpeedSpan = styled.span(({ theme }) => ([
    theme.splus3.typography.defaultBold,
]));

const UnitSpan = styled.span(({ theme }) => ([
    theme.splus3.typography.smallBold,
]));

const InfoDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.smallLite,
    {
        lineHeight: "18px",

        strong: [
            theme.splus3.typography.small,
        ]
    }
]));

const ProgressBarContainer = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: "100%",
    }
]));

const Divider = styled.line(({ theme }) => ([
    theme.splus3.background.monochrome3,
    {
        display: "block",
        height: 1,
    }
]));

export const ButtonDiv = styled(CommonButtonDiv)(
    {
        display: "flex",
        padding: 6,
        minWidth: 0,
        alignSelf: "center",
        marginRight: 16,
        cursor: "pointer",
    }
);

interface HistoryRecordDetailsProps {
    records: NQRecord[];
    record: NQRecord;
}

export const HistoryRecordDetailsDesktop: React.FC<HistoryRecordDetailsProps> = ({ records, record }) => {
    const dispatch = useAppDispatch();

    const bookedDownloadSpeedMax = nullOrValue(record.prepareResult.init.modem.bookedDownloadSpeedMax);
    const bookedDownloadSpeedAvg = nullOrValue(record.prepareResult.init.modem.bookedDownloadSpeedAvg);
    const bookedDownloadSpeedMin = nullOrValue(record.prepareResult.init.modem.bookedDownloadSpeedMin);

    const bookedUploadSpeedMax = nullOrValue(record.prepareResult.init.modem.bookedUploadSpeedMax);
    const bookedUploadSpeedAvg = nullOrValue(record.prepareResult.init.modem.bookedUploadSpeedAvg);
    const bookedUploadSpeedMin = nullOrValue(record.prepareResult.init.modem.bookedUploadSpeedMin);

    const downloadProgress = (currentSpeedMbps: number | null) => {
        if (bookedDownloadSpeedMax === null ||
            bookedDownloadSpeedAvg === null ||
            bookedDownloadSpeedMin === null ||
            currentSpeedMbps === null) {
            return null;
        }

        const max = bookedDownloadSpeedMax * 1e-6;
        const avg = bookedDownloadSpeedAvg * 1e-6;
        const min = bookedDownloadSpeedMin * 1e-6;
        const current = currentSpeedMbps * 1e-6;

        const speedKind = getSpeedKind(current, avg, min);

        return (
            <ProgressBar speedKind={speedKind} min={min} avg={avg} max={max} current={current} showCompareText={false} />
        );
    }

    const uploadProgress = () => {
        if (bookedUploadSpeedMax === null ||
            bookedUploadSpeedAvg === null ||
            bookedUploadSpeedMin === null) {
            return null;
        }

        const uploadFinalSpeed = nullOrValue(record.uploadFinal.speed);

        if (uploadFinalSpeed === null) {
            return null;
        }

        const max = bookedUploadSpeedMax * 1e-6;
        const avg = bookedUploadSpeedAvg * 1e-6;
        const min = bookedUploadSpeedMin * 1e-6;
        const current = uploadFinalSpeed * 1e-6;

        const speedKind = getSpeedKind(current, avg, min);

        return (
            <ProgressBar speedKind={speedKind} min={min} avg={avg} max={max} current={current} showCompareText={false} />
        );
    }

    const units = historyRecordSpeedUnits(records);
    const values = historyRecordSpeedValues(records, record);

    const routerInfo = (): JSX.Element => {
        switch (record.modemFinal.modemFinal.kind) {
            case ModemFinalKind.ModemFinalAvailable:
                return <>
                    <span><SpeedSpan>{values.routerSpeedStr ?? "--"}</SpeedSpan>&nbsp;<UnitSpan>{units.routerSpeedUnit}</UnitSpan></span>
                    {R.isNil(record.prepareResult.init.modem.name) ? (
                        <InfoDiv>
                            {t("Messung zu Deinem Anschluss")}
                        </InfoDiv>
                    ) : (
                        <InfoDiv>
                            {t("Messung zu Deinem Anschluss")}: <strong>{record.prepareResult.init.modem.name}</strong> ({t("am Router")})
                        </InfoDiv>
                    )}

                    <ProgressBarContainer>
                        {downloadProgress(getRouterSpeed(record.modemFinal))}
                    </ProgressBarContainer>
                </>

            case ModemFinalKind.ModemFinalFailed:
                return <>
                    <SpeedSpan>{t("Fehler")} </SpeedSpan>
                    <InfoDiv>
                        {t("Die Messung hat leider nicht geklappt. Versuch es bitte später nochmal.")}
                    </InfoDiv>
                </>

            case ModemFinalKind.ModemFinalDidNotRun:
                switch (record.modemFinal.modemFinal.reason) {
                    case ModemNotRunReason.NotNeeded:
                        return <>
                            <SpeedSpan>{t("Messung nicht notwendig")} </SpeedSpan>
                            <InfoDiv>
                                {t("Dein Endgerät liefert bereits eine tolle Geschwindigkeit")}
                            </InfoDiv>
                        </>
                    case ModemNotRunReason.NotACustomer:
                        return <>
                            <SpeedSpan>{t("Messung nicht verfügbar")} </SpeedSpan>
                            <InfoDiv>
                                {t("Nur bei Vodafone-Kunden mit Kabel-Anschluss möglich")}
                            </InfoDiv>
                        </>
                    case ModemNotRunReason.NotAvailable:
                        return <>
                            <SpeedSpan>{t("Fehler")} </SpeedSpan>
                            <InfoDiv>
                                {t("Messung zum Router in Deinem Versorgungsbereich aktuell nicht möglich.")}
                            </InfoDiv>
                        </>
                }
        }
    }

    return (
        <RowContainer>
            <RowDiv data-cy="history-details">
                <FirstCellDiv>
                    <CheckBoxContainer>
                    </CheckBoxContainer>
                </FirstCellDiv>

                <SimpleCellDiv>
                    {formatDate(record.date)} - {formatTime(record.date)}
                    <span data-cy="span-speedtest-id">{getSpeedtestId(record.prepareResult)}</span>
                </SimpleCellDiv>
                <ExtendedCellDiv>
                    {routerInfo()}
                </ExtendedCellDiv>
                <ExtendedCellDiv>
                    <span><SpeedSpan>{values.downloadSpeedStr}</SpeedSpan>&nbsp;<UnitSpan>{units.downloadSpeedUnit}</UnitSpan></span>
                    <InfoDiv>
                        {getDeviceText(record.prepareResult, t("Messung an Deinem Gerät"))}
                    </InfoDiv>
                    <ProgressBarContainer>
                        {downloadProgress(record.downloadFinal.speed)}
                    </ProgressBarContainer>
                </ExtendedCellDiv>
                <ExtendedCellDiv>
                    <span><SpeedSpan>{values.uploadSpeedStr}</SpeedSpan>&nbsp;<UnitSpan>{units.uploadSpeedUnit}</UnitSpan></span>
                    <InfoDiv>
                        {t("Messung von Deinem Anschluss")}
                    </InfoDiv>
                    <ProgressBarContainer>
                        {uploadProgress()}
                    </ProgressBarContainer>
                </ExtendedCellDiv>
                <InlineCellDiv>
                    <span><SpeedSpan>{formatPingTime(record.pingFinal.pingTime)}</SpeedSpan>&nbsp;<UnitSpan>{t("ms")}</UnitSpan></span>
                    <div style={{ alignSelf: "center" }}>
                        <NavigationButton dataCy="print-report-button" onClick={() => dispatch(printReport(record))}>
                            <ButtonDiv>
                                <img src={exportSvg.src} alt="export" width={24} height={24} />
                            </ButtonDiv>
                        </NavigationButton>
                    </div>
                </InlineCellDiv>
            </RowDiv>
            <Divider />
            <BottomInfo date={record.date} prepareResult={record.prepareResult} />
        </RowContainer>
    );
}

export default HistoryRecordDetailsDesktop;