import styled from "@emotion/styled";
import { useAppDispatch } from "../../../hooks";
import ModalDialog, {  } from "../elements/ModalDialog";
import t from "../../../helper/t";
import { closePrivacyPolicyDialog } from "../../../features/privacyPolicy/privacyPolicySlice";
import { startMeasurement3 } from "../measurement/startMeasurement3";
import markdownRender from "../../../helper/markdownRender";
import NavigationButton from "../elements/NavigationButton";
import { DefaultButtonDiv } from "../elements/Buttons";

const ContentContaner = styled.div(({ theme }) => ([
    theme.splus3.typography.default,
    {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",

        p: [
            {
                marginTop: 0,
                marginBottom: 12,
            }

        ],
    }
]));

const ButtonsContainer = styled.div(({ theme }) => ([
    {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        marginTop: 12,
        marginBottom: 24,
    }
]));

const PrivacyPolicyDialog: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const onClose = (accepted: boolean) => {
        dispatch(closePrivacyPolicyDialog(accepted));
        if (accepted) {
            dispatch(startMeasurement3());
        }
    }

    return (
        <ModalDialog header={t("Hinweis")} onClose={() => onClose(false)}>
            <ContentContaner>
                <div dangerouslySetInnerHTML={{ __html: markdownRender(t("Privacy Policy Content")) }} />
            </ContentContaner>
            <ButtonsContainer>
                <NavigationButton isFocused={true} onClick={() => onClose(true)} isDialog={true}>
                    <DefaultButtonDiv>{t("Speedtest Plus starten")}</DefaultButtonDiv>
                </NavigationButton>
            </ButtonsContainer>
            <ContentContaner>
                <div dangerouslySetInnerHTML={{ __html: markdownRender(t("Privacy Policy Footer")) }} />
            </ContentContaner>
        </ModalDialog>
    );
}

export default PrivacyPolicyDialog;