import styled from "@emotion/styled";
import { Speed, SpeedContainer, SubSpeed, SubtitleSpan, SubtitleRightSpan, SubtitleContainer } from "../resultElements";
import { ModemFinalKind, ModemNotRunReason } from "@visonum/network-quality-sdk";
import HeaderWithTooltip from "../../../elements/HeaderWithTooltip";
import { currentLangIs, nullOrValue } from "../../../../../helper/utils";
import t from "../../../../../helper/t";
import { progress, connectionSubtitle } from "./utils";
import RouterError from "../RouterError";
import { DownloadCardProps } from "./DownloadCardProps";
import Percentage from "../Percentage";

import InfoCircleSvg from "@assets/icons/splus3/download-info-circle.svg";
import downloadDevice2TooltipDe from "@assets/tooltips/de/download_device_2.json";
import downloadDevice2TooltipEn from "@assets/tooltips/en/download_device_2.json";

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexGrow: 10,
        padding: 20,
        columnGap: "3.6vw",

        [theme.splus3.breakpoints.smallDesktop]: {
            columnGap: "5vw",
        },

        [theme.splus3.breakpoints.mobile]: {
            flexDirection: "column",
            rowGap: 25,
            padding: 16,
        },
    }
]));

const LeftPartDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        flexGrow: 10,
    }
]);

const RightPartDiv = styled.div(({ theme }) => ([
    theme.splus3.background.primary,
    theme.splus3.shadow.primary,
    {
        display: "flex",
        justifyContent: "space-between",
        columnGap: 16,
        minWidth: 140,
        maxWidth: 420,
        width: "24vw",
        borderRadius: 6,
        padding: 20,

        [theme.splus3.breakpoints.mobile]: {
            width: "auto",
            columnGap: 8,
            paddingLeft: 12,
            paddingRight: 12,
        },
    }
]));

const RightContentDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
    }
]));

const DownloadCard2: React.FC<DownloadCardProps> = ({ record, showAdditionalInfo }) => {
    const downloadSpeed = record.downloadFinal.speed;
    const modemFinal = record.modemFinal.modemFinal;
    const modem = record.prepareResult.init.modem;

    const bookedDownloadSpeedMax = nullOrValue(modem.bookedDownloadSpeedMax);
    const bookedDownloadSpeedAvg = nullOrValue(modem.bookedDownloadSpeedAvg);
    const bookedDownloadSpeedMin = nullOrValue(modem.bookedDownloadSpeedMin);
    const routerName = nullOrValue(modem.name);

    const modemProgress = (): JSX.Element | null => {
        switch (modemFinal.kind) {
            case ModemFinalKind.ModemFinalAvailable:
                return progress(bookedDownloadSpeedMin, bookedDownloadSpeedAvg, bookedDownloadSpeedMax, modemFinal.speed, showAdditionalInfo);
            case ModemFinalKind.ModemFinalDidNotRun:
                switch (modemFinal.reason) {
                    case ModemNotRunReason.NotNeeded: // should never display
                        return (
                            <RouterError title={t("Messung nicht notwendig")}>
                                {t("Dein Endgerät liefert bereits eine tolle Geschwindigkeit")}
                            </RouterError>
                        );
                    case ModemNotRunReason.NotACustomer:
                        return (
                            <RouterError title={t("Messung nicht verfügbar")}>
                                {t("Nur bei Vodafone-Kunden mit Kabel-Anschluss möglich")}
                            </RouterError>
                        );
                    case ModemNotRunReason.NotAvailable:
                        return (
                            <RouterError title={t("Messung zum Router aktuell nicht möglich")}>
                                {t("Wir arbeiten daran, Dir dieses Angebot so schnell wie möglich in Deinem Versorgungsbereich wieder zu ermöglichen.")}
                            </RouterError>
                        );
                }
                break;

            case ModemFinalKind.ModemFinalFailed:
                return (
                    <RouterError title={t("Fehler")}>
                        {t("Die Messung von unserem Server zu Deinem Router hat leider nicht geklappt. Versuch es bitte später nochmal.")}
                    </RouterError>
                );
        }
    }

    const downloadTooltip = currentLangIs("en") ? downloadDevice2TooltipEn : downloadDevice2TooltipDe;

    return (
        <RootDiv>
            <LeftPartDiv>
                {modemFinal.kind == ModemFinalKind.ModemFinalAvailable && (
                    <SpeedContainer>
                        <Speed currentSpeed={modemFinal.speed} bookedSpeedMax={bookedDownloadSpeedMax} />
                        <Percentage
                            currentSpeed={modemFinal.speed}
                            bookedSpeedMax={bookedDownloadSpeedMax}
                            bookedSpeedAvg={bookedDownloadSpeedAvg}
                            bookedSpeedMin={bookedDownloadSpeedMin}
                            showAdditionalInfo={showAdditionalInfo}
                        />
                    </SpeedContainer>
                )}
                <SubtitleContainer>
                    {routerName === null ? (
                        <SubtitleSpan>
                            {t("Messung an Deinem Anschluss")}
                        </SubtitleSpan>
                    ) : (
                        <SubtitleSpan>
                            {t("Messung an Deinem Anschluss")}: <strong>{routerName}</strong> (Router)
                        </SubtitleSpan>
                    )}
                </SubtitleContainer>
                {modemProgress()}
            </LeftPartDiv>

            <RightPartDiv>
                <RightContentDiv>
                    <SubSpeed currentSpeed={downloadSpeed} bookedSpeedMax={bookedDownloadSpeedMax} />
                    <SubtitleContainer>
                        <SubtitleRightSpan>
                            {connectionSubtitle(record)}
                        </SubtitleRightSpan>
                    </SubtitleContainer>
                </RightContentDiv>
                {showAdditionalInfo && (
                    <HeaderWithTooltip header={downloadTooltip.hintHeader}
                        handlePosition="BottomLeft"
                        icon={InfoCircleSvg}
                        size={19}
                        left={-26}
                        bottom={28}>
                        {downloadTooltip.hintContent}
                    </HeaderWithTooltip>
                )}
            </RightPartDiv>
        </RootDiv>
    );
}

export default DownloadCard2;