import styled from "@emotion/styled";
import { SpeedContainer, SpeedDiv, SpeedUnit, SpeedValue, SubtitleContainer, SubtitleSpan } from "./resultElements";
import { getPingTimeKind } from "../common/resultUtils";
import { formatPingTime } from "../../../../helper/unitsFormatting";
import PingProgressBar from "../common/PingProgressBar";
import t from "../../../../helper/t";

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        flexGrow: 10,
        padding: 20,

        [theme.splus3.breakpoints.mobile]: {
            padding: 16,
        },
    }
]));

interface PingCardProps {
    pingTime: number;
    minTime?: number;
    avgTime?: number;
    maxTime?: number;
    showCompareText?: boolean;
}

const PingCard: React.FC<PingCardProps> = ({ pingTime, minTime = 0.015, avgTime = 0.035, maxTime = 0.050, showCompareText = true }) => {
    const min = Math.round(minTime * 1e3);
    const max = Math.round(maxTime * 1e3);
    const avg = Math.round(avgTime * 1e3);
    const current = Math.round(pingTime * 1e3);
    const timeKind = getPingTimeKind(current, min, avg, max);

    return (
        <RootDiv>
            <SpeedContainer>
                <SpeedDiv>
                    <SpeedValue>
                        {formatPingTime(pingTime)}&nbsp;
                    </SpeedValue>
                    <SpeedUnit>
                        {t("ms")}
                    </SpeedUnit>
                </SpeedDiv>
            </SpeedContainer>
            <SubtitleContainer>
                <SubtitleSpan>
                    {t("Reaktionszeit Deines Anschlusses")}
                </SubtitleSpan>
            </SubtitleContainer>
            <PingProgressBar pingTimeKind={timeKind} min={min} avg={avg} max={max} current={current} showCompareText={showCompareText} />
        </RootDiv>
    );
}

export default PingCard;